import IntroImg from '../../images/intro_pic.jpg';
import { Page } from '../Page';

const PageNav = () => {
	const navLinks = [
		{
			text: 'About',
			path: '#about',
		},
		{
			text: 'Portfolio',
			path: '#portfolio',
		},
		{
			text: 'Contact',
			path: '#contact',
		},
	];
	return (
		<div className='flex flex-col my-4 w-full md:w-1/2 md:ml-auto'>
			{navLinks.map((link, index) => {
				return (
					<>
						<div key={`link-${index}`} className='py-4'>
							<a href={link.path}>{link.text}</a>
						</div>
						{index < navLinks.length - 1 && <hr />}
					</>
				);
			})}
		</div>
	);
};

export const IntroSection = () => {
	return (
		<Page bgColor='bg-cream' id='intro'>
			<div className='flex flex-col flex-1 md:flex-row justify-between'>
				<div className='flex flex-col self-center mx-auto'>
					<h4 className='text-rust'>Software Consulting</h4>
					<h1 className='text-gold'>Sara Piette</h1>
					<PageNav />
				</div>
				<div className='flex-1'>
					<img
						src={IntroImg}
						alt='tree portrait'
						className='block  md:w-3/4 ml-auto'
					/>
				</div>
			</div>
		</Page>
	);
};
