import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import React from 'react';

type FormValues = {
	fullName: string;
	email: string;
	message: string;
};

export const ContactForm = () => {
	const methods = useForm<FormValues>();
	const { handleSubmit, register } = methods;

	const submitForm: SubmitHandler<FormValues> = (values: FormValues) => {
		console.log('VALUES!', values);
	};

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={handleSubmit(submitForm)}
				className='my-8 mx-auto flex flex-col justify-center max-w-xl'
			>
				<div className='flex flex-col lg:flex-row justify-between'>
					<div className='my-4 lg:w-1/2 flex flex-col'>
						<label className='my-2'>Name*</label>
						<input
							type='text'
							placeholder='First & Last Name'
							{...register('fullName')}
							required={true}
							className='border border-gold rounded-md py-4 px-2'
						/>
					</div>
					<div className='my-4 lg:m-4 lg:w-1/2 flex flex-col'>
						<label className='my-2'>Email*</label>
						<input
							type='email'
							placeholder='you@example.com'
							{...register('email')}
							required={true}
							className='border border-gold rounded-md py-4 px-2'
						/>
					</div>
				</div>
				<div className='my-4 flex flex-col'>
					<label className='my-2'>Message*</label>
					<textarea
						rows={5}
						cols={50}
						{...register('message')}
						required={true}
						className='border border-gold rounded-md py-4 px-2'
					/>
				</div>

				<button
					type='submit'
					className='block my-2 bg-rust hover:bg-peach text-cream py-2 px-4 rounded-md w-full md:w-1/2 self-end'
				>
					Send
				</button>
			</form>
		</FormProvider>
	);
};
