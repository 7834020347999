import AboutImg from '../../images/about_pic.jpg';
import { Page } from '../Page';
import { SocialMedia } from '../atoms/SocialMedia';

export const AboutMeSection = () => {
	return (
		<Page bgColor='bg-gold' id='about'>
			<div className='flex flex-col md:flex-row'>
				<div className='flex flex-1 flex-col md:flex-row justify-between'>
					<div className='flex flex-col mx-auto w-full md:w-1/2 order-0 md:order-1 text-darkBlue'>
						<h2>About Me</h2>
						<p>
							I'm a software engineer who specializes in building human centered
							experiences. With over 10 years of professional experience, I have
							worked in various industries and company sizes, ranging from seed
							stage mental health startups to Fortune 500 retailers. I love
							diving into complex data modeling problems and using technology to
							build beautiful and intuitive user experiences that make a
							difference in people's lives. Reach out if you would like to learn
							more about how we could work together!
						</p>
						<div className='my-8 self-center md:self-start'>
							<button className='bg-rust text-cream py-2 px-4 mr-4 hover:bg-peach'>
								Resume
							</button>
							<button className='bg-rust text-cream py-2 px-4 mx-4 hover:bg-peach'>
								Portfolio
							</button>
						</div>
					</div>
					<div className='flex flex-1 flex-col order-0'>
						<img
							src={AboutImg}
							alt='about portrait'
							className='block w-full md:w-3/4 rounded-t-full'
						/>
						<SocialMedia classNames='flex flex-row mt-4 justify-center md:justify-start' />
					</div>
				</div>
			</div>
		</Page>
	);
};
