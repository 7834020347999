import { AiFillGithub, AiFillLinkedin } from 'react-icons/ai';

import React from 'react';

export interface SocialMediaProps {
	classNames: string;
}
export const SocialMedia = (props: SocialMediaProps) => {
	const socialLinks = [
		{
			url: 'https://www.linkedin.com/in/sarapiette/',
			icon: <AiFillLinkedin size='32px' />,
		},
		{
			url: 'https://github.com/sepiette',
			icon: <AiFillGithub size='32px' />,
		},
	];
	return (
		<div className={props.classNames}>
			{socialLinks.map((link, index) => {
				return (
					<a
						key={`social-${index}`}
						href={link.url}
						target='_blank'
						rel='noreferrer'
						className='mr-2'
					>
						{link.icon}
					</a>
				);
			})}
		</div>
	);
};
