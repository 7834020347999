import { Page } from '../Page';
import React from 'react';

export const PortfolioSection = () => {
	return (
		<Page bgColor='bg-darkBlue' id='portfolio'>
			<div className='flex flex-col text-cream'>
				<h2>Portfolio</h2>
				<h3>Coming Soon...</h3>
			</div>
		</Page>
	);
};
