import { ContactForm } from '../organisms/ContactForm';
import { Page } from '../Page';
import React from 'react';

export const ContactSection = () => {
	return (
		<Page bgColor='bg-cream' id='contact'>
			<h2 className='text-rust text-center'>Contact</h2>
			<ContactForm />
		</Page>
	);
};
