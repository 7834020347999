import { Route, Routes } from 'react-router-dom';

import Home from './components/pages/Home';

function App() {
	return (
		<div className='h-screen bg-cream'>
			<Routes>
				<Route path='' element={<Home />} />
			</Routes>
		</div>
	);
}

export default App;
