import { AboutMeSection } from '../sections/AboutMeSection';
import { ContactSection } from '../sections/ContactSection';
import { IntroSection } from '../sections/IntroSection';
import { PortfolioSection } from '../sections/PortfolioSection';

const Home = () => {
	return (
		<>
			<IntroSection />
			<AboutMeSection />
			<PortfolioSection />
			<ContactSection />
		</>
	);
};

export default Home;
