import React from 'react';

export interface PageProps {
	children: React.ReactNode;
	id: string;
	bgColor: string;
	classNames?: string;
}

export const Page = (props: PageProps) => {
	return (
		<div id={props.id} className={`${props.bgColor} ${props.classNames} `}>
			<div className={`max-w-7xl	mx-auto p-8 lg:p-16`}>{props.children}</div>
		</div>
	);
};
